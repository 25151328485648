import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'componentes';
import { Typography, Spin } from 'antd';
import { AutoSizer } from 'react-virtualized';
import { CardStyled } from './styles';
import { useTemplate } from './Provider';
import { aux } from '../data/useCadastro';

const { Title } = Typography;
function PageHeader({ title, newButton, isLoading }) {
  return (
    <Row>
      <Col span={16}>
        <Title level={3} style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
          {title}
          {isLoading && <Spin />}
        </Title>
      </Col>
      <Col span={8} style={{ textAlign: 'right', padding: '0px 15px' }}>
        <Button loading={newButton.isLoading} onClick={newButton.onClick}>
          {newButton.text}
        </Button>
      </Col>
    </Row>
  );
}

function TemplateLayout({
  title,
  FormConsulta,
  FormCadastro,
  ResultadoConsulta,
  textoBotaoNovo = 'textoBotaoNovo',
  dataForm: dataFormPromise,
}) {
  const { actions, data, dispatch } = useTemplate();
  const usuario = useSelector((state) => state.usuario);
  const { dataForm } = data;
  useEffect(() => {
    if (dataFormPromise) {
      actions.loadDataForm(dataFormPromise);
    }
    actions.executaAcaoConformeUrl();
  }, [dispatch, dataFormPromise]);

  const newButton = {
    text: textoBotaoNovo,
    onClick: actions.abrirNovo,
    isLoading: data.cadastro.isLoading && aux.ehModoInclusao(data),
  };
  // eslint-disable-next-line no-console
  return (
    <div
      style={{
        padding: '10px 0px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader title={title} newButton={newButton} isLoading={dataForm.isLoading} />
      {!dataForm.isLoading && (
        <>
          <Row>
            <Col span={24}>
              <CardStyled>
                {FormConsulta && <FormConsulta usuario={usuario} {...data.dataForm} />}
              </CardStyled>
            </Col>
          </Row>

          <Row style={{ flex: '1' }}>
            <Col span={24} style={{ height: '98%' }}>
              <CardStyled style={{ height: '100%' }}>
                <AutoSizer>
                  {({ height, width }) => (
                    <div style={{ height: '100%', width: width - 3 }}>
                      <ResultadoConsulta
                        usuario={usuario}
                        {...data.resultado}
                        height={height}
                        width={width}
                        editar={actions.editar}
                      />
                    </div>
                  )}
                </AutoSizer>
              </CardStyled>
            </Col>
          </Row>

          {aux.ehModoCadastro(data) && <FormCadastro {...data.cadastro} />}
        </>
      )}
    </div>
  );
}

export default TemplateLayout;
