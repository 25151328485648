/* eslint-disable no-param-reassign */
import compose from 'compose-function';
import omitEmpty from 'omit-empty';

export const cpfMask = (value) => value
  .replace(/\D/g, '')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  .replace(/(-\d{2})\d+?$/, '$1');

export const promiseProps = (promises, map) => {
  const values = Object.values(promises).map((f) => (typeof f === 'function' ? f() : f));
  const keys = Object.keys(promises);
  const load = Promise.all(values);
  return load.then((resultado) => resultado.reduce((data, r, i) => {
    data[keys[i]] = map ? map(r) : r;
    return data;
  }, {}));
};

const replaceRange = (obj) => Object.entries(obj).reduce((novoObjeto, [key, value]) => {
  if (Array.isArray(value) && value.length === 2 && key.indexOf('~') > 0) {
    const [nome1, nome2] = key.split('~');
    const [valor1, valor2] = value;
    return {
      ...novoObjeto,
      [nome1]: valor1,
      [nome2]: valor2,
    };
  }
  novoObjeto[key] = value;
  return novoObjeto;
}, {});

export const sanitizeQuery = compose(
  replaceRange,
  omitEmpty,
);

export const fromEntries = (iterable) => [...iterable].reduce((obj, [key, val]) => {
  obj[key] = val;
  return obj;
}, {});

export const objectMap = (object, mapFn) => Object.keys(object).reduce((result, key) => {
  result[key] = mapFn(object[key]);
  return result;
}, {});
