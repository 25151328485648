/* eslint-disable */
import React from 'react';
import {
  Layout, Row, Col,
} from 'antd';
import logoRhmed from 'assets/images/rhmed-logo-branca2.png';

import UsuarioButton from './UsuarioButton';
import MenuPrincipal from './MenuPrincipal';

const { Header } = Layout;

function HeaderPage({ pages, urlSelecionada, propsColunaResponsiva }) {
  return (
    <Header className="header">
      <Row>
        <Col {...propsColunaResponsiva}>
          <div className="fixedHeaderContainer">
            <div className="logo">
              <img src={logoRhmed} height="42" alt="Logo" />
            </div>
            <MenuPrincipal pages={pages} urlSelecionada={urlSelecionada} />
            <UsuarioButton />
          </div>
        </Col>
      </Row>
    </Header>
  );
}

export default HeaderPage;
