import React from 'react';
import { TResultadoConsulta } from 'templates/Cadastro';
import { Button } from 'componentes';
import { EditOutlined } from '@ant-design/icons';

const createColumns = ({ editar }) => [
  {
    title: 'Nome',
    dataIndex: 'nome',
    key: 'nome',
    width: '30%',
  },
  {
    title: 'Perfil',
    dataIndex: 'perfil',
    key: 'perfil',
    width: '20%',
  },
  {
    title: 'Empresa',
    dataIndex: 'empresa',
    key: 'empresa',
    width: '40%',
  },
  {
    title: 'Ação',
    dataIndex: 'id',
    key: 'action',
    render: (id) => (
      <Button size="small" icon={<EditOutlined />} onClick={() => editar(id)} />
    ),
  },
];

function ResultadoConsulta({ editar, ...restProps }) {
  const columns = createColumns({ editar });
  return <TResultadoConsulta columns={columns} {...restProps} />;
}

export default ResultadoConsulta;
