import { get, download as actionDownload } from 'lib/api';
import fileDownload from 'js-file-download';

const endPoint = '/relatorios/eventos';

export const download = (id) => get(`${endPoint}/${id}/download`).then((response) => {
  const { data } = response;
  fileDownload(data, `${id}.xml`);
});

export const getEventoInvalido = (id) => get(`eventos/${id}/erros`).then((response) => {
  const { data } = response;
  return data;
});

export const downloadZipResultado = (query) => actionDownload('relatorios/eventos/zip', query).then((response) => {
  fileDownload(response.data, 'arquivos.zip');
});
