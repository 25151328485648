import React, { useState, useEffect } from 'react';
import { Modal, Spin, Table } from 'antd';
import { getEventoInvalido } from '../../data/actions';

const columns = [
  {
    title: 'Erro',
    dataIndex: 'mensagem',
  },
  {
    title: 'Valor Recebido',
    dataIndex: 'valor',
  },
];

function EventoErrosDetalhe({ idEvento, onOk }) {
  const [data, setData] = useState(null);
  useEffect(() => {
    getEventoInvalido(idEvento).then(setData);
  }, [idEvento]);
  return (
    <Modal title="Evento erros" footer={null} onOk={onOk} onCancel={onOk} visible>
      {data ? <Table dataSource={data} columns={columns} pagination={false} /> : <Spin />}
    </Modal>
  );
}

export default EventoErrosDetalhe;
