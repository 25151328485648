import React from 'react';
import { TFormCadastro } from 'templates/Cadastro';
import { Row, Input, Select } from 'componentes';
import validationSchema from './validationSchema';

function FormCadastro({ data }) {
  const { ftp, protocolos } = data;
  return (
    <TFormCadastro initialValues={ftp} title="Configuração FTP" validationSchema={validationSchema}>
      <Row>
        <Input label="Nome" name="nome" span={8} required />
      </Row>
      <Row gutter={2}>
        <Select label="Protocolo" name="idProtocolo" options={protocolos} span={4} required />

        <Input label="Host" name="host" span={12} required />
        <Input label="Porta" name="porta" span={8} type="number" min={1} max={65000} required />
      </Row>
      <Row>
        <Input label="Pasta" name="folder" />
      </Row>
      <Row gutter={2}>
        <Input label="Usuário" name="usuario" span={12} required />
        <Input label="Senha" name="password" span={12} required />
      </Row>
    </TFormCadastro>
  );
}

export default FormCadastro;
