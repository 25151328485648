import { get } from 'lib/api';
import store from 'store';
import convertSwaggerToRoutes from './data/converterSwaggerToRoutes';

const cache = {
  token: undefined,
  content: undefined,
};

const hasCache = () => cache.token === store.get('usuario').token;

const loadSwaggerJSON = () => {
  if (hasCache()) {
    return Promise.resolve(cache.content);
  }
  return get('/swagger.json').then((response) => {
    const { data } = response;
    cache.content = convertSwaggerToRoutes(data);
    cache.token = store.get('usuario').token;
    return cache.content;
  });
};

export default { loadSwaggerJSON };
