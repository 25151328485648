import { message } from 'antd';
import { promiseProps } from 'lib/utils';

export const ACTION_SET_DATA_FORM = 'ACTION_SET_DATA_FORM';
export const ACTION_SET_RESULTADO = 'SET_RESULTADO';

// eslint-disable-next-line no-unused-vars
const consultar = ({ dispatch, restApi, historico }) => (query) => {
  dispatch({ type: ACTION_SET_RESULTADO, data: { isLoading: true } });
  return restApi
    .consultar(query)
    .then((response) => {
      dispatch({ type: ACTION_SET_RESULTADO, data: { isLoading: false, data: response.data } });
      // historico.push(`?${qs.stringify(query)}`);
      const { data } = response;
      data.query = query;
      return data;
    })
    .catch((e) => {
      message.error(JSON.stringify(e.response.data));
    });
};

const loadDataForm = ({ dispatch }) => (dataForm) => {
  dispatch({ type: ACTION_SET_DATA_FORM, data: { isLoading: true } });
  promiseProps(dataForm, ({ data }) => data).then((data) => {
    dispatch({ type: ACTION_SET_DATA_FORM, data: { isLoading: false, data } });
  });
};
const createActions = (tools) => ({
  consultar: consultar(tools),
  loadDataForm: loadDataForm(tools),
});

export default createActions;
