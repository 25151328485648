// eslint-disable-next-line default-param-last, max-len
const reduceRemoveDuplicados = (lista = [], item) => (lista.includes(item) ? lista : [...lista, item]);

const reduceFlatten = (a, b) => a.concat(b);

const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1);

const buscarTags = (routes) => routes
  .map((route) => route.tags)
  .reduce(reduceFlatten, [])
  .reduce(reduceRemoveDuplicados, [])
  .map((tag) => ({
    label: capitalize(tag),
    tag: (tag.startsWith('S') ? `Evento ${tag}` : tag),
  }))
  .sort();

const criarMenuData = (swaggerData, urlBase, urlSelecionada) => {
  const menusDI = buscarTags(swaggerData);

  return {
    defaultSelectedKeys: [urlSelecionada || 'Introdução'],
    defaultOpenKeys: ['Começando'],
    itens: [
      {
        label: 'Começando',
        itens: [
          { label: 'Introdução', url: `${urlBase}/introducao` },
          { label: 'Autenticação', url: `${urlBase}/introducao#autenticacao` },
        ],
      },
      {
        label: 'Documentação interativa',
        itens: menusDI.map((menu) => ({ label: menu.label, url: `${urlBase}/interativa/${menu.tag}` })),
      },
    ],
  };
};

export default criarMenuData;
