import React from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import TForm from './TForm';
import { useTemplate } from '../Provider';

const selectQueryString = (search) => qs.parse(search.substring(1));

function TFormConsulta({ children }) {
  const location = useLocation();
  const { actions } = useTemplate();
  const queryString = selectQueryString(location.search);
  return (
    <TForm actions={actions} initialValues={queryString}>
      {children}
    </TForm>
  );
}

export default TFormConsulta;
