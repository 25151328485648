import store from 'store';

const stateDefault = { estaLogado: false };

const getUsuarioStore = () => {
  const usuario = store.get('usuario');
  if (usuario && usuario.token) {
    const body = usuario.token.split('.')[1];
    const credentials = JSON.parse(window.atob(body));
    return {
      estaLogado: true,
      perfil: credentials.role,
    };
  }
  return stateDefault;
};

const initialState = getUsuarioStore();

// eslint-disable-next-line default-param-last
function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'USER_LOGOUT':
      return stateDefault;
    case 'USUARIO_UPDATE':
      return { ...state, perfil: action.data.perfil, estaLogado: true };
    default:
      return state;
  }
}

export default rootReducer;
