import React, { Fragment } from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import InputParameter from './InputParameter';

const Name = styled.span`
  display: block;
  font-weight: 600;
  line-height: 22px;
`;

const Required = styled.span`
  color: #ef6363;
  display: block;
  font-weight: 700;
  line-height: 22px;
  font-size: 10px;
`;

const Title = styled.div`
  color: #aeaeae;
  display: block;
  font-weight: 500;
  line-height: 22px;
  border-bottom: 1px solid #ddd;
  margin-top: 10px;
  padding-left: 10px;
`;

const formatType = (props) => {
  const { type, format, items } = props;

  if (format === 'date') {
    return 'ISO 8601 date format';
  }

  if (type === 'array' && items) {
    return `Array of ${items.type}`;
  }

  return type;
};

function ColumnName(name, record) {
  return (
    <>
      <Name>{name}</Name>
      {' '}
      {formatType(record)}
    </>
  );
}

function DescriptionName(description, { required }) {
  return (
    <>
      {required && <Required>REQUERIDO</Required>}
      {' '}
      {description}
    </>
  );
}

const columns = [
  {
    title: 'Parâmetro',
    dataIndex: 'name',
    key: 'name',
    render: ColumnName,
    width: '25%',
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
    render: DescriptionName,
    width: '40%',
  },
  {
    title: 'Valor',
    dataIndex: 'tipoEvento',
    key: 'tipoEvento',
    render: (text, parameter) => <InputParameter key={parameter.id} parameter={parameter} />,
    width: '35%',
  },
];

const locale = {
  emptyText: 'Sem parametros',
};

const titles = {
  query: 'QUERY PARAMS',
  Body: 'BODY PARAMS',
  header: 'HEADER PARAMS',
};

const Parameters = (props) => {
  const { parameters } = props;

  return Object.entries(parameters).map(([propertyIn, parametros]) => (
    <Fragment key={propertyIn}>
      <Title>{titles[propertyIn]}</Title>
      <Table
        showHeader={false}
        rowKey="name"
        dataSource={parametros}
        columns={columns}
        pagination={false}
        locale={locale}
      />
    </Fragment>
  ));
};

export default Parameters;
