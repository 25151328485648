/* eslint-disable no-console */
import { Modal } from 'antd';
import { post } from 'lib/api';

export const ok = 'dxx';

export const dispararEnvio = (id) => () => post('/ftp/envio', { id })
  .then(() => {
    Modal.success({
      title: 'Processamento iniciado, verificar o resultado no relatórios de transmissões.',
      // content: `${response.data.qtdEnvios} arquivos enviados.`,
    });
  })
  .catch((error) => console.log(error));
