import store from 'store';
import { post } from 'lib/api';
import history from 'lib/history';

const login = (data, fallback) => (dispatch) => post('/login', data)
  .then((response) => {
    const { token } = response.data;

    const body = token.split('.')[1];
    const credentials = JSON.parse(window.atob(body));
    store.set('usuario', { token });
    dispatch({
      type: 'USUARIO_UPDATE',
      data: {
        perfil: credentials.role,
      },
    });
    history.push('/');
  })
  .catch((error) => {
    console.log('response', error.message);
    const { response } = error;
    if (response && response.status === 401) {
      return fallback('usuário inválido');
    }
    // eslint-disable-next-line no-alert
    return alert('Erro ao tentar logar.');
  });
export default { login };
