import React from 'react';
import { TFormConsulta } from 'templates/Consulta';

import {
  Row, Select, DatePeriodo, Input,
} from 'componentes';
import * as perfil from 'lib/perfil';

const toOptions = (data, label, value) => {
  if (Array.isArray(data)) {
    return data.map((d) => ({
      label: d[label],
      value: d[value],
    }));
  }
  return [];
};

function FormConsulta({ usuario, data }) {
  const ehCliente = perfil.ehClient(usuario);

  const tiposDeEvento = toOptions(data.tiposDeEvento, 'nome', 'id');
  const empresas = toOptions(data.empresas, 'nome', 'id');
  const statusDeEvento = toOptions(data.statusDeEvento, 'nome', 'id');

  const span = 8;
  const spanCPFCNPJ = ehCliente ? span : 4;
  const selectEmpresa = ehCliente ? null : (
    <Select label="Empresa" name="idEmpresa" allowClear options={empresas} span={span} />
  );

  const selectStatus = ehCliente ? null : (
    <Select label="Evento Status" name="idEventoStatus" allowClear options={statusDeEvento} span={span} />
  );

  return (
    <TFormConsulta>
      <Row gutter={4}>
        <DatePeriodo label="Período" name="dataEventoInicio~dataEventoTermino" span={span} />
        <Select label="Tipo de Evento" allowClear name="idTipoEvento" options={tiposDeEvento} span={span} mode="multiple" />
        {selectStatus}
      </Row>
      <Row gutter={4}>
        <Input label="CPF" name="cpf" span={spanCPFCNPJ} />
        <Input label="CNPJ" name="cnpj" span={spanCPFCNPJ} />
        {selectEmpresa}
        <DatePeriodo label="S2240 - Data Inicio condição" name="datIniCondicaoInicio~datIniCondicaoTermino" span={span} />
      </Row>
    </TFormConsulta>
  );
}

export default FormConsulta;
