import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TemplateContent from 'pages/Main/components/TemplateContent';
import Content from './Content';
import actions from './actions';
import criarMenuData from './criarMenuData';

const urlBase = '/apidocumentacao';

function APIDoc({ setError }) {
  const [swaggerData, setSwaggerData] = useState(null);

  const location = useLocation();

  useEffect(() => {
    actions
      .loadSwaggerJSON()
      .then((data) => {
        setSwaggerData(data);
      })
      .catch(() => setError('Error ao acessar a API'));
  }, [setError, setSwaggerData]);
  if (!swaggerData) {
    return <div>Loading</div>;
  }
  const menuData = criarMenuData(swaggerData, urlBase, location.pathname);
  return (
    <TemplateContent menuData={menuData}>
      <Content location={location} urlBase={urlBase} swaggerData={swaggerData} />
    </TemplateContent>
  );
}

export default APIDoc;
