import isSandbox from './isSandbox';

const getUrlBase = () => {
  if (process.env.NODE_ENV === 'production') {
    return `${window.location.protocol}//api.${window.location.host}`;
  }
  if (isSandbox()) {
    return process.env.REACT_APP_SANDBOX_URL;
  }
  return process.env.REACT_APP_BASE_URL;
};

export default getUrlBase;
