/* eslint-disable */
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Introducao from './pages/Introducao';
import DocumentacaoInterativa from './pages/DocumentacaoInterativa';

const criarDocumentacaoInterativa = (swaggerData) => function _criarDocumentacaoInterativa(props) {
  return <DocumentacaoInterativa swaggerData={swaggerData} {...props} />;
};

function Content(props) {
  const location = useLocation();

  const { urlBase, swaggerData } = props;

  return (
    <Routes key="Switch">
      <Route path={`introducao`} element={<Introducao />} />
      <Route
        path={`interativa/*`}
        element={<DocumentacaoInterativa location={location} swaggerData={swaggerData} />}
      />
      <Route path={`/`} element={<Introducao />} />
    </Routes>
  );
}

export default Content;
