import React from 'react';
import { TFormConsulta } from 'templates/Cadastro';
import { Row, Input, Select } from 'componentes';

function FormConsulta({ data }) {
  const { empresas } = data;

  const optionsEmpresa = Array.isArray(empresas) ? empresas.map((em) => ({
    label: em.nome,
    value: em.id,
  })) : [];

  return (
    <TFormConsulta>
      <Row gutter={2}>
        <Input label="Nome" name="nome" span={8} />
        <Select label="Empresa" name="idEmpresa" options={optionsEmpresa} span={12} allowClear />
      </Row>
    </TFormConsulta>
  );
}

export default FormConsulta;
