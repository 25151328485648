/* eslint-disable */
import React, { useState } from 'react';
import {
  Form, Button, Row, Col,
} from 'componentes';
import { sanitizeQuery } from 'lib/utils';


function TForm({ children, actions, initialValues }) {

  const [isSubmitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };

  const onFinish = (query) => {
    actions.consultar(sanitizeQuery(query)).finally(() => setSubmitting(false));
    setSubmitting(true);
  };

  return (

    <Form labelAlign="left" onFinish={onFinish} initialValues={initialValues} layout="vertical">
      <Row>
        <Col span={24} style={{ textAlign: 'left', padding: '15px 15px 0px' }}>
          {children}
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right', padding: '5px 15px 10px', height: '50px' }}>
          <Button type="primary" disabled={isSubmitting} htmlType="submit">
            Consultar
          </Button>
          <Button htmlType="button" onClick={onReset} style={{ marginLeft: 8 }}>Resetar</Button>
        </Col>
      </Row>
    </Form>

  );
}

export default TForm;
