import React from 'react';
import { Form, Input } from 'antd';
import withSpan from './hocs/withSpan';

const getRules = (required = false, min = 0) => {
  const rules = [];

  if (required) {
    rules.push(
      {
        required,
        message: 'Campo obrigatório.',
      },
    );
  }

  if (min > 0) {
    rules.push(
      { type: 'string', min: 8 },
    );
  }

  return rules;
};

function MInput({
  label, name, type = 'text', required = false,
  min,
}) {
  const rules = getRules(
    required,
    min,
  );

  return (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
    >
      <Input type={type} />
    </Form.Item>
  );
}

export default withSpan(MInput);
