import { ACTION_SET_RESULTADO, ACTION_SET_DATA_FORM } from './actions';

const reducer = (state, { type, data }) => {
  switch (type) {
    case ACTION_SET_RESULTADO:
      return { ...state, resultado: data };
    case ACTION_SET_DATA_FORM:
      return { ...state, dataForm: data };
    default:
      throw new Error();
  }
};

export default reducer;
