import React from 'react';
import TemplateContent from 'pages/Main/components/TemplateContent';
import Content from './Content';

const urlBase = '/admin';

const menuData = (urlSelecionada) => ({
  defaultSelectedKeys: [urlSelecionada || `${urlBase}/usuario`],
  // defaultOpenKeys: ['Começando'],
  itens: [
    { label: 'Usuários', url: `${urlBase}/usuarios` },
  ],
});

function Admin({ location }) {
  return (
    <TemplateContent menuData={menuData(location.pathname)}>
      <Content location={location} urlBase={urlBase} />
    </TemplateContent>
  );
}

export default Admin;
