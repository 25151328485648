import React from 'react';
import { Form, Select } from 'antd';
import withSpan from './hocs/withSpan';

export function MSelect({
  label, name, options = [], required = false, allowClear, mode,
}) {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required,
          message: 'Campo Obrigatório',
        },
      ]}
    >
      <Select options={options} allowClear={allowClear} mode={mode} />
    </Form.Item>
  );
}

export default withSpan(MSelect);
