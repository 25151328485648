import normalizarParamentros from './normalizarParametros';
import addAcceptParameter from './addAcceptParameter';
import buildInitialValues from './buildInitialValues';

const mapEntries = (obj, fn) => Object.entries(obj).map(fn);

// eslint-disable-next-line max-len
const converterSwaggerToRoutes = ({ paths }) => mapEntries(paths, ([url, metodos]) => mapEntries(metodos, ([method, definicao]) => {
  const { parameters = [], produces } = definicao;
  const parametersWithAccept = addAcceptParameter(parameters, produces);
  const parametersNormalizados = normalizarParamentros(parametersWithAccept);
  return {
    url,
    method,
    ...definicao,
    parameters: parametersNormalizados,
    initialValues: buildInitialValues(parametersNormalizados),
  };
})).reduce((a, b) => a.concat(b), []);

export default converterSwaggerToRoutes;
