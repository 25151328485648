import {
  ACTION_SET_DATA_FORM,
  ACTION_SET_MODO_CADASTRO,
  ACTION_SET_MODO_CONSULTA,
  ACTION_SET_RESULTADO,
  ACTION_SET_DADO_CADASTRO,
} from './actions';

export const log = (reducer) => (state, action) => {
  const newState = reducer(state, action);
  return newState;
};

const reducer = (state, { type, data }) => {
  switch (type) {
    case ACTION_SET_DATA_FORM:
      return { ...state, dataForm: data };
    case ACTION_SET_MODO_CADASTRO:
      return {
        ...state,
        modo: data,
        cadastro: { isLoading: true, data: {} },
      };
    case ACTION_SET_MODO_CONSULTA:
      return {
        ...state,
        modo: data,
        cadastro: { isLoading: false },
      };
    case ACTION_SET_RESULTADO:
      return { ...state, resultado: data };
    case ACTION_SET_DADO_CADASTRO: {
      return { ...state, cadastro: { ...data, isLoading: false } };
    }
    default:
      throw new Error('Tipo de action não identificado');
  }
};

export default reducer;
