/* eslint-disable */
import React from 'react';
import { DatePicker, Form } from 'antd';
import dayjs from 'dayjs';
import withSpan from './hocs/withSpan';

const convertValueToISO = (values) => {
  if (Array.isArray(values)) {
    return values.map((date) => date && date.toISOString());
  }
  return values;
};


const convertValueToDayJS = (values) => {
  if (Array.isArray(values)) {
    return values.map((value) => (value ? dayjs(value) : undefined));
  }
  return values;
};


function MDatePeriodo(props) {
  const {
    label, name, required = false,
  } = props;

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required,
          message: 'Campo Obrigatório',
        },
      ]}
      // getValueFromEvent={convertValueToISO}
      getValueFromEvent={convertValueToISO}
      getValueProps={convertValueToDayJS}
    >
      <DatePicker.RangePicker
        name={name}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
}

export default withSpan(MDatePeriodo);
