import React from 'react';
import { Input, Select } from 'componentes';

const definirPrefixo = (parameter) => {
  switch (parameter.in) {
    case 'query':
      return 'query';
    case 'path':
      return 'params';
    case 'header':
      return 'header';
    default:
      return 'payload';
  }
};

const definirType = (parameter) => {
  switch (parameter.format) {
    case 'date':
      return 'date';
    default:
      switch (parameter.type) {
        case 'todo':
          return 'todo'; // todo
        default:
          switch (parameter.name) {
            case 'email':
              return 'email'; // todo
            case 'password':
              return 'password'; // todo
            default:
              return 'text';
          }
      }
  }
};

const getDefaultValue = (parameter) => {
  if (parameter.default) {
    return parameter.default;
  }
  return parameter.required ? null : '';
};

function SelectInput(props) {
  const { name, options, mode } = props;

  const os = options.map((valor) => ({
    label: valor,
    value: valor,
  }));
  return (
    <Select mode={mode} name={name} options={os} />
  );
}

function InputParameter({ parameter }) {
  const prefixo = definirPrefixo(parameter);
  const type = definirType(parameter);
  const defaultValue = getDefaultValue(parameter);
  const name = [prefixo, parameter.name]; // `${prefixo}.${parameter.name}`;
  const comumProps = { name, defaultValue };

  if (parameter.enum) {
    const selectProps = { ...comumProps, options: parameter.enum };
    return <SelectInput {...selectProps} />;
  }

  if (parameter.type === 'array' && parameter.items.enum) {
    const selectProps = { ...comumProps, options: parameter.items.enum, mode: 'multiple' };
    return <SelectInput {...selectProps} />;
  }

  if (parameter.type === 'array' && parameter.items.type === 'string') {
    const selectProps = { ...comumProps, options: [], mode: 'tags' };
    return <SelectInput {...selectProps} />;
  }
  const inputProps = { ...comumProps, type };
  return <Input {...inputProps} />;
}

export default InputParameter;
