import React from 'react';
import { createRestApi } from 'lib/api';
import { TemplateProvider } from './componentes/Provider';
import TemplateLayout from './componentes/Layout';
import criarNavegacao from './navegacao';
import createActions from './data/actions';

function TemplaCadastro({
  endPoint, url, dataForm, ...restProps
}) {
  const restApi = createRestApi(endPoint);
  const historico = criarNavegacao(url);
  const actions = createActions({ restApi, historico });
  return (
    <TemplateProvider actions={actions} dataForm={dataForm}>
      <TemplateLayout dataForm={dataForm} {...restProps} />
    </TemplateProvider>
  );
}

export { default as TFormConsulta } from './componentes/TFormConsulta';
export { default as TResultadoConsulta } from './componentes/TResultadoConsulta';
export { default as TFormCadastro } from './componentes/TFormCadastro';

export default TemplaCadastro;
