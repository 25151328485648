import { useReducer } from 'react';
import history from 'lib/history';
import { MODO_INCLUSAO, MODO_EDICAO, MODO_CONSULTA } from './const';
import reducer from './reducer';

const modosDeCadastro = [MODO_INCLUSAO, MODO_EDICAO];

export const aux = {
  ehModoCadastro: ({ modo }) => modosDeCadastro.includes(modo),
  ehModoInclusao: ({ modo }) => modo === MODO_INCLUSAO,
  ehModoEdicao: ({ modo }) => modo === MODO_EDICAO,
};

const definirModoPelaUrl = (pathname) => {
  const ultimaParte = pathname.split('/').pop();
  if (ultimaParte === 'edit') {
    return MODO_EDICAO;
  }
  if (ultimaParte === 'novo') {
    return MODO_INCLUSAO;
  }
  return MODO_CONSULTA;
};

const initialState = (dataForm) => {
  const { pathname } = history.location;
  const modo = definirModoPelaUrl(pathname);
  const cadastro = aux.ehModoCadastro({ modo })
    ? { isLoading: true, data: {} }
    : { isLoading: false };
  const resultado = { isLoading: false };
  const state = {
    modo,
    pathname,
    cadastro,
    resultado,
    dataForm: { isLoading: !!dataForm, data: {} },
  };
  return state;
};

const useCadastro = ({ actions, dataForm }) => {
  const [data, dispatch] = useReducer(reducer, initialState(dataForm));

  return {
    actions: actions(data, dispatch),
    data,
    dispatch,
  };
};

export default useCadastro;
