import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
  nome: Yup.string().required('Obrigatório'),
  host: Yup.string().required('Obrigatório'),
  porta: Yup.string().required('Obrigatório'),
  usuario: Yup.string().required('Obrigatório'),
  password: Yup.string().required('Obrigatório'),
});

export default SignupSchema;
