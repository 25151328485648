import React from 'react';
import { Col } from 'antd';

const withSpan = (Component) => function _withSpan({ span, ...restProps }) {
  if (span) {
    return (
      <Col span={span}>
        <Component {...restProps} />
      </Col>
    );
  }
  return <Component {...restProps} />;
};

export default withSpan;
