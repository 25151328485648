import React from 'react';
import { useLocation } from 'react-router-dom';
import { Layout, Row, Col } from 'antd';
// import { useSelector } from 'react-redux';
import ContentMain from './ContentMain';
import { withPages } from './components/Pages';
import Header from './components/Header';

const { Content, Footer } = Layout;

const propsColunaResponsiva = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: { span: 20, offset: 2 },
  xxl: { span: 16, offset: 4 },
};

function Main(props) {
  const location = useLocation();
  const { pathname } = location;
  const { pages } = props;
  return (
    <Layout style={{ height: '100%', backgroundColor: '#fbfbfb', overflowY: 'auto' }}>
      <Header
        pages={pages}
        urlSelecionada={pathname}
        propsColunaResponsiva={propsColunaResponsiva}
      />
      <Content style={{ overflow: 'overlay' }}>
        <Row style={{ height: '100%' }}>
          <Col {...propsColunaResponsiva} style={{ height: '100%' }}>
            <div style={{ height: '100%' }}>

              <ContentMain pages={pages} location={location} />
            </div>
          </Col>
        </Row>
      </Content>
      <Footer style={{ textAlign: 'center' }}>2019 Created by RHMED Team</Footer>
    </Layout>
  );
}

export default withPages(Main);
