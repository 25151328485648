import React from 'react';
import { TFormConsulta } from 'templates/Cadastro';

import { Row, Input } from 'componentes';

function FormConsulta() {
  return (
    <TFormConsulta>
      <Row>
        <Input label="Nome" name="nome" span={8} />
      </Row>
    </TFormConsulta>
  );
}

export default FormConsulta;
