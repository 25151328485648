import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Eventos from './pages/Eventos';

function Content() {
  return (
    <Routes key="Routes">
      <Route path="eventos" element={<Eventos />} />
      <Route path="/" element={<Eventos />} />
    </Routes>
  );
}

export default Content;
