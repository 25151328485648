import React from 'react';
import { Layout } from 'antd';
import MenuLateral from '../MenuLateral';

const { Content, Sider } = Layout;

function TemplateContent({ menuData, children }) {
  return (
    <Layout style={{ padding: '0px 0', background: '#fff', height: '100%' }}>
      {!!menuData && (
      <Sider width={220} style={{ background: '#fff', height: '100%', overflow: 'overlay' }}>
        <MenuLateral data={menuData} style={{ paddingTop: '5%', height: '100%' }} />
      </Sider>
      )}
      <Content style={{ padding: '0 24px', minHeight: 280, background: 'rgb(251, 251, 251)' }}>{children}</Content>
    </Layout>
  );
}

export default TemplateContent;
