import React from 'react';
import { TResultadoConsulta } from 'templates/Cadastro';
import { Button } from 'componentes';
import { dispararEnvio } from '../data/actions';

const createColumns = ({ editar }) => [
  {
    title: 'Nome',
    dataIndex: 'nome',
    key: 'nome',
  },
  {
    title: 'Protocolo',
    dataIndex: 'protocolo',
    key: 'protocolo',
  },
  {
    title: 'Host',
    dataIndex: 'host',
    key: 'host',
  },
  {
    title: 'Porta',
    dataIndex: 'porta',
    key: 'porta',
  },
  {
    title: 'Usuário',
    dataIndex: 'usuario',
    key: 'usuario',
  },

  {
    title: 'Ação',
    dataIndex: 'id',
    key: 'action',
    render: (id) => (
      <>
        <Button size="small" icon="edit" onClick={() => editar(id)} />
        <Button size="small" icon="play-circle" onClick={dispararEnvio(id)} />
      </>
    ),
  },
];

function ResultadoConsulta({ editar, ...restProps }) {
  const columns = createColumns({ editar });
  return <TResultadoConsulta columns={columns} {...restProps} />;
}

export default ResultadoConsulta;
