import React from 'react';
import { createRestApi } from 'lib/api';
import { TemplateProvider } from './componentes/Provider';
import TemplateLayout from './componentes/Layout';
import criarNavegacao from './navegacao';

function TemplaConsulta({
  endPoint, url, dataForm, ...restProps
}) {
  const restApi = createRestApi(endPoint);
  const historico = criarNavegacao(url);
  return (
    <TemplateProvider restApi={restApi} historico={historico} dataForm={dataForm}>
      <TemplateLayout dataForm={dataForm} {...restProps} />
    </TemplateProvider>
  );
}

export { default as TFormConsulta } from './componentes/TFormConsulta';
export { default as TResultadoConsulta } from './componentes/TResultadoConsulta';

export default TemplaConsulta;
