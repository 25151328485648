import React, { Fragment, useState } from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import Request from './Request';
import Response from './Response';

const gridStyle = {
  width: '100%',
  padding: '5px 20px',
};

const Metodo = styled.span`
  background-color: #57c3bc;
  padding: 5px;
  color: #fff;
  width: 50px;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
  border-radius: 3px;
  font-weight: 500;
`;

const Url = styled.span`
  font-weight: 500;
  font-size: 18px;
`;

function Title({ metodo, endPoint }) {
  return (
    <>
      <Metodo key="metodo">{metodo.toUpperCase()}</Metodo>
      <Url key="url">{endPoint}</Url>
    </>
  );
}

function Recurso(props) {
  const [response, setResponse] = useState(null);
  const { route } = props;
  return (
    <Fragment key={route.operationId}>
      <Card title={<Title metodo={route.method} endPoint={route.url} />}>
        <Card.Grid style={gridStyle} key="Request">
          <Request route={route} setResponse={setResponse} />
        </Card.Grid>
        <Card.Grid style={gridStyle} key="Response">
          <Response route={route} response={response} />
        </Card.Grid>
      </Card>
      <br />
    </Fragment>
  );
}

export default Recurso;
