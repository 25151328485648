import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Usuarios from './pages/Usuarios';

function Content() {
  return (
    <Routes key="Routes">
      <Route path="usuarios" element={<Usuarios />} />
      <Route path="/" element={<Usuarios />} />
    </Routes>
  );
}

export default Content;
