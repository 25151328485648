import { createStore, applyMiddleware } from 'redux';
// import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import createRootReducer from './reducers';
import history from './lib/history';

const configureStore = (preloadedState) => createStore(
  createRootReducer(history),
  preloadedState,
  //  applyMiddleware(thunk, logger, routerMiddleware(history)),
  applyMiddleware(thunk, logger),
);

export default configureStore;
