import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Introducao from './pages/Introducao';
import Configuracao from './pages/Configuracao';
import Transmissoes from './pages/Transmissoes';

function Content(props) {
  const { location, urlBase } = props;
  return (
    <Routes pathname={location.pathname} key="Routes">
      <Route path={`${urlBase}/introducao`} component={Introducao} />
      <Route path={`${urlBase}/configuracao`} component={Configuracao} />
      <Route path={`${urlBase}/transmissoes`} component={Transmissoes} />

      <Route path={`${urlBase}/`} component={Introducao} />
    </Routes>
  );
}

export default Content;
