import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Button } from 'antd';
import Parameters from './Parameters';
import { executarAPI } from './action';

const Description = styled.p`
  margin: 10px 0px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 18px;
`;

const DivButton = styled.div`
  margin: 5px auto;
  width: 200px;
`;

function Request({ route, setResponse }) {
  const [isSubmitting, setSubmitting] = useState(false);
  const { description, initialValues } = route;

  const onFinish = (values) => {
    executarAPI({ setResponse, route, data: values }).finally(() => setSubmitting(false));
  };
  return (
    <Form initialValues={initialValues} onFinish={onFinish}>
      <Description>{description}</Description>
      <br />
      <Parameters parameters={route.parameters} />
      <DivButton>
        <Button type="primary" disabled={isSubmitting} htmlType="submit">
          Enviar
        </Button>
      </DivButton>
    </Form>
  );
}

export default Request;
