/* eslint-disable */
import React, { useState, useCallback } from 'react';

import {
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import {
  Button, Input, Form, Alert,
} from 'antd';

import {
  UserOutlined,
  LockOutlined,
} from '@ant-design/icons';

import actions from './actions';
import LogoRHMED from './componentes/LogoRHMED';

const handleSubmit = (dispatch, fallback) => (values) => {
  console.log(values)
  dispatch(actions.login(values, fallback));

};

function LoginForm({ form }) {
  const dispatch = useDispatch();
  const [token, setToken] = useState();
  const [mensagemErro, setMensagemErro] = useState('');

  const onVerify = useCallback((token) => {
    setToken(token);
  });
  if(!token){
    return <GoogleReCaptcha
    onVerify={onVerify}
  />
  }

  return (
    <>
      <LogoRHMED />
      {mensagemErro && <Alert message={mensagemErro} type="error" />}
      <br />
      <Form name="normal_login" onFinish={handleSubmit(dispatch,(mensagemErro)=>{
        setMensagemErro(mensagemErro)
        setToken("");
    })}>
       <Form.Item name="recaptcha" initialValue={token} >
          <Input name="recaptcha" type="hidden" value={token}/>
        </Form.Item>
        <Form.Item name="login" rules={[{ required: true, message: 'Por favor digite a login!' }]}>
          <Input name="login" size="default" prefix={<UserOutlined />} placeholder="Login" />

        </Form.Item>
        <Form.Item name="senha" rules={[{ required: true, message: 'Por favor digite a senha!' }]}>
          <Input
            size="default"
            prefix={<LockOutlined />}
            type="password"
            placeholder="Senha"
          />
        </Form.Item>
        <br />
        <Button type="primary" style={{ width: '100%' }} htmlType="submit">
          Log in
        </Button>
      </Form>
    </>
  );
}

const WrappedLoginForm = LoginForm;

export default WrappedLoginForm;
