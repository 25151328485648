import React from 'react';
import { useSelector } from 'react-redux';
import Home from 'pages/Home';
import APIDoc from 'pages/APIDoc';
import FTP from 'pages/FTP';
import Admin from 'pages/Admin';
import Relatorios from 'pages/Relatorios';

const PERFIL_USUARIO = {
  TI: 1,
  ADMINISTRADOR: 2,
  CLIENTE: 3,
  INTEGRADOR: 4,
};

const pages = [
  {
    url: '/',
    text: 'Home',
    content: Home,
  },
  { url: '/apidocumentacao', text: 'API Documentação', content: APIDoc },
  {
    url: '/ftp', text: 'FTP', content: FTP, allow: () => false,
  },
  { url: '/relatorios/eventos', text: 'Relatórios', content: Relatorios },
  {
    url: '/admin/usuarios',
    text: 'Admin',
    content: Admin,
    allow: (idPerfil) => idPerfil !== PERFIL_USUARIO.CLIENTE,
  },
];

const getPages = (idPerfil) => pages.filter((page) => {
  if (page.allow) {
    return page.allow(idPerfil);
  }
  return true;
});

export const withPages = (Componente) => function _withPages(props) {
  const usuario = useSelector((state) => state.usuario);
  return <Componente {...props} pages={getPages(usuario.perfil)} />;
};

export const buscarPaginaSelecionada = (pathname) => pages
  .slice()
  .reverse()
  .filter(({ url }) => pathname.startsWith(url))[0];
