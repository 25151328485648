import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'componentes';
import { Typography, Spin } from 'antd';
import { AutoSizer } from 'react-virtualized';
import { CardStyled } from './styles';
import { useTemplate } from './Provider';
import 'react-virtualized/styles.css';

const { Title } = Typography;

function TemplateLayout({
  title, FormConsulta, ResultadoConsulta, dataForm: dataFormPromise,
}) {
  const usuario = useSelector((state) => state.usuario);
  const { data, actions } = useTemplate();
  const { dataForm } = data;

  useEffect(() => {
    if (dataFormPromise) {
      actions.loadDataForm(dataFormPromise);
    }
  }, [dataFormPromise]);

  return (
    <div
      style={{
        padding: '10px 0px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Row>
        <Col span={24}>
          <Title level={3} style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
            {title}
            {' '}
            {dataForm.isLoading && <Spin />}
          </Title>
        </Col>
      </Row>
      {!dataForm.isLoading && (
        <>
          <Row>
            <Col span={24}>
              <CardStyled>
                {FormConsulta && <FormConsulta usuario={usuario} {...data.dataForm} />}
              </CardStyled>
            </Col>
          </Row>
          <Row style={{ flex: '1' }}>
            <Col span={24} style={{ height: '98%' }}>
              <CardStyled>
                <AutoSizer>
                  {({ height, width }) => (
                    <div className="xxxx" style={{ height: '100%', width: width - 3 }}>
                      <ResultadoConsulta
                        usuario={usuario}
                        {...data.resultado}
                        height={height}
                        width={width}
                      />
                    </div>
                  )}
                </AutoSizer>
              </CardStyled>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default TemplateLayout;
