import React from 'react';
import styled from 'styled-components';

const Titulo = styled.h3`
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 15px;
  line-height: 20px;
  margin-top: 0px;
  font-weight: bold;
`;

const H2 = styled.h2`
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;
  font-weight: bold;
`;

const Texto = styled.p`
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.7;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  padding: 15px 0px;
  line-height: 25px;
  font-size-adjust: 100%;
`;
const DivPrincipal = styled.div`
  padding: 15px 30px;
  /*border: 1px solid rgba(0, 0, 0, 0.1);*/
  background-color: #fff;
`;

const Hr = styled.div`
  padding: 0px 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;
function Introducao() {
  return (
    <DivPrincipal>
      <H2>Introdução à API</H2>
      <Texto>
        API é o acrônimo de application programming interface, ou seja, a
        especificação da interface para integração entre sistemas. O objetivo da
        API é fornecer os layouts de SST do ESocial para envio ao governo.
      </Texto>
      <br />
      <Titulo>Requisitos</Titulo>
      <Texto>
        Para utilizar nossa API é necessário que você tenha familiaridade com a
        terminologia básica utilizada no desenvolvimento de web services REST.
        possível desenvolver a integração com praticamente todas as linguagens de
        programação disponíveis no mercado.
      </Texto>
      <br />
      <Titulo>Testes</Titulo>
      <Texto>
        Você pode testar a API Esocial da RHMED através dos formulários dentro da
        própria documentação interativa (na sessão documentação interativa ao
        lado), lado), podendo observar as requisições e as respostas.
      </Texto>
      <br />
      <Hr />
      <br />
      <Titulo>Especificações técnicas</Titulo>
      <br />

      <Titulo>Formato</Titulo>
      <Texto>
        A API Esocial é um web service REST com os métodos tradicionais POST e GET
        disponíveis. O retorno das requisições é realizado no formato JSON ou XML,
        sempre acompanhado do código HTTP apropriado.
      </Texto>
      <Titulo name="autenticacao">Segurança e autenticação</Titulo>
      <Texto>
        A API utiliza a tecnologia JWT (Json Web Token) para a autenticação e, por
        isso, antes de qualquer requisição aos endpoints, faz-se necessária a
        obtenção de um token de acesso que deverá ser enviado como parâmetro de
        autenticação no header &quot;Authorization&quot;. Para conseguir o token
        basta fazer uma requisição para o endpoint &quot;/autenticar&quot;
        passando o login e a senha utilizada para acessar esta plataforma.
      </Texto>
      <Titulo>Parâmetros</Titulo>
      <Texto>
        Cada layout possui data de início e fim do evento como parâmetros
        obrigatórios e CPF e CNPJ como opcionais. Parâmetros opcionais podem ser
        simplesmente omitidos ou então informados com um valor nulo, usando null.
      </Texto>
      <Titulo>Versionamento e manutenção</Titulo>
      <Texto>
        O Esocial está em constante evolução, hoje estamos fornecendo os layouts
        de acordo com a versão 2.5 diponibilizada pelo governo em novembro de 2018,
        mas estamos sempre atentos ao surgimento de novas versões e as atualização
        ocorrerão de acordo com o prazo fornecido pelo governo.

      </Texto>
    </DivPrincipal>
  );
}

export default Introducao;
