import React from 'react';
import styled from 'styled-components';

const H2 = styled.h2`
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;
  font-weight: bold;
`;

const Texto = styled.p`
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.7;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  padding: 15px 0px;
  line-height: 25px;
  font-size-adjust: 100%;
`;
const DivPrincipal = styled.div`
  padding: 15px 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;
function Introducao() {
  return (
    <DivPrincipal>
      <H2>Introdução a integração por FTP</H2>
      <Texto>
        FTP é um protocolo usado para transferir arquivos. O objetivo da
        transferência via FTP é fornecer os arquivos no formato do Esocial de
        acordo com cada evento para envio ao governo.
      </Texto>
      <H2>Configuração</H2>
      <Texto>
        Será necessário informar os dados para conexão: Host, porta, protocolo,
        usuário e senha.
      </Texto>
      <H2>Funcionamento</H2>
      <Texto>
        A cada duas horas o sistema verificará a existência de novos eventos e
        enviará ao FTP configurado.
        {' '}
      </Texto>
    </DivPrincipal>
  );
}

export default Introducao;
