import React from 'react';
import { TResultadoConsulta } from 'templates/Consulta';
import moment from 'moment'; // TODO: remove moment

const columns = [
  {
    title: 'Nome',
    dataIndex: 'nome',
    key: 'nome',
  },
  {
    title: 'Iniciada Em',
    dataIndex: 'iniciadaEm',
    key: 'iniciadaEm',
    render: (iniciadaEm) => moment(iniciadaEm).format('DD/MM/YYYY hh:mm:ss'),
  },
  {
    title: 'Terminada em',
    dataIndex: 'terminadaEm',
    key: 'terminadaEm',
    render: (terminadaEm) => moment(terminadaEm).format('DD/MM/YYYY  hh:mm:ss'),
  },
  {
    title: 'Transmitidos',
    dataIndex: 'qtdArquivosTransmitidos',
    key: 'qtdArquivosTransmitidos',
  },
  {
    title: 'Previstos',
    dataIndex: 'qtdArquivosPrevistos',
    key: 'qtdArquivosPrevistos',
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

function ResultadoConsulta(props) {
  return (
    <TResultadoConsulta
      columns={columns}
      rowKey="iniciadaEm"
      {...props}
      rowClassName={(record) => (record.status === 'Falha' ? 'ant-alert-error' : '')}
    />
  );
}

export default ResultadoConsulta;
