import history from 'lib/history';

const criarNavegacao = (urlBase) => ({
  consulta: () => history.push(urlBase),
  insercao: () => history.push(`${urlBase}/novo`),
  edicao: (id) => history.push(`${urlBase}/${id}/edit`),
  push: (data) => history.push(data),
  getLocation: () => history.location,
});

export default criarNavegacao;
