import React from 'react';
import {
  Layout, Row, Col, Card,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import isSandbox from 'lib/isSandbox';
import H1 from './H1';
import Sandbox from './ContentSandbox';

function TituloComum() {
  return (
    <div>
      <H1>Bem vindo ao portal de integrações para o Esocial da RHMED.</H1>
    </div>
  );
}

function Titulo() {
  if (isSandbox()) {
    return <Sandbox.Titulo />;
  }
  return <TituloComum />;
}

function ChamadaSandBox() {
  if (isSandbox()) {
    return null;
  }
  return <Sandbox.Chamada />;
}

function Content() {
  return (
    <Layout.Content style={{ padding: '50px 5px' }}>
      <div>
        <Titulo />
        <br />
        <br />
        <H1>Conheça nossas formas de integração.</H1>
        <br />
        <br />
        <Row gutter={8}>
          <Col span={8}>
            <Card
              title="API"
              extra={<Link to="/apidocumentacao/">Saiba mais</Link>}
              style={{ width: '100%', height: 204 }}
            >
              <p>
                Com nossa API automatize todo o processo, desde a consulta dos eventos até a
                confirmação do processamento através do recibo.
              </p>
            </Card>
          </Col>
          {/*  <Col span={8}>
          <Card
            title="FTP"
            extra={<Link to="/ftp/">Saiba mais</Link>}
            style={{ width: '100%', height: 204 }}
          >
            <p>
              Apenas disponibilize um servidor FTP e receba a cada duas horas o xml dos eventos
              realizados.
            </p>
          </Card>
        </Col>
        */}
          <Col span={8}>
            <Card
              title="Relatórios"
              extra={<Link to="/relatorios/">Saiba mais</Link>}
              style={{ width: '100%', height: 204 }}
            >
              <p>
                Caso não tenha a possibilidade de integrar pela API ou por FTP, disponibilizamos um
                relatório onde é possível fazer o download do xml dos eventos.
              </p>
            </Card>
          </Col>
        </Row>
      </div>
      <br />
      <br />
      <ChamadaSandBox />
      <br />
    </Layout.Content>
  );
}

export default connect()(Content);
