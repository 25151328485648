const normalizarPayload = (parameter) => {
  if (parameter.in === 'body') {
    const definition = parameter.schema;

    return Object.entries(definition.properties).map(([propertyName, values]) => ({
      ...values,
      name: propertyName,
      in: 'Body',
    }));
  }
  if (parameter.in === 'header') {
    return {
      ...parameter,
      name: parameter.name.replace(/\b\w/g, (l) => l.toUpperCase()),
    };
  }
  return parameter;
};

const order = {
  header: 1,
  query: 2,
  Body: 3,
};

const normalizarParamentros = (parametros) => parametros
  .map(normalizarPayload)
  .reduce((a, b) => a.concat(b), [])
  .sort((a, b) => order[a.in] > order[b.in])
  .reduce((grupo, parameter) => {
    const propertyIn = grupo[parameter.in]
      ? grupo[parameter.in].concat([parameter])
      : [parameter];

    return {
      ...grupo,
      [parameter.in]: propertyIn,
    };
  }, {});
export default normalizarParamentros;
