import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import Form from './Form';

const Card = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px 20px 60px;
  box-shadow: 1px 1px 2px 0 #ccc;
`;

const getReCaptchaKey = () => {
  if (process.env.NODE_ENV === 'production') {
    return '6LetIz4pAAAAAN83hSxawr78hE8VUwU-iRTRnkJW';
  }
  return '6LdUwkMpAAAAAMqqZ_i4_UJl0b3i__hgmiO0SMZP';
};

function Login() {
  return (
    <div style={{ height: '100%' }}>
      <GoogleReCaptchaProvider reCaptchaKey={getReCaptchaKey()}>
        <Row type="flex" justify="center" align="middle" style={{ height: '100%' }}>
          <Col>
            <Card>
              <Form />
            </Card>
          </Col>
        </Row>
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default Login;
