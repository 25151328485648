import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const { SubMenu } = Menu;

const StyledLink = styled(Link)`
  font-size: 14px;
`;

const fontSize = '14px';

const criarMenu = ({ label, url, key }) => (
  <Menu.Item key={key || url}>
    <StyledLink to={url}>{label}</StyledLink>
  </Menu.Item>
);

const criarMenuItens = (itens) => itens.map(criarMenu);

const criarSubMenu = (item) => (item.itens ? (
  <SubMenu key={item.key || item.label} title={<span style={{ fontSize }}>{item.label}</span>}>
    {criarMenuItens(item.itens)}
  </SubMenu>
) : (
  criarMenu(item)
));

function MenuLateral({ data, style }) {
  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={data.defaultSelectedKeys}
      defaultOpenKeys={data.defaultOpenKeys}
      style={{ ...style }}
    >
      {data.itens.map(criarSubMenu)}
    </Menu>
  );
}

export default MenuLateral;
