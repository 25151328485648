/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

const getTypeStyle = ({ sucesso }) => {
  if (sucesso) {
    return '  background-color: #fcf6db; border: 1px solid #e5e0c6;';
  }
  return '   background-color: #f2dede; border: 1px solid #eed3d7; color: #b94a48;';
};

const Content = styled.pre`
  padding: 10px;
  overflow: auto;
  word-wrap: break-word;
  width: ${({ width }) => width - 10}px;
  max-height: 400px;
  line-height: 20px;
  ${getTypeStyle};
`;

const Container = styled.div`
  padding: 10px 0px 5px;
`;

function Propriedade({ label, content, sucesso = true }) {
  return (
    <>
      <div>{label}</div>
      <Content sucesso={sucesso}>{content}</Content>
    </>
  );
}

// eslint-disable-next-line no-unused-vars
const ehJson = (headers) => headers['content-type'].includes('application/json');

const stringifyChaveValor = (data) => Object.entries(data)
  .map(([key, value]) => `${key}:${value}`)
  .join('\n');

const getPropriedades = (response) => {
  const {
    request, data, status, statusText, config,
  } = response;

  return {
    Url: request.responseURL,
    'Request Headers': stringifyChaveValor(config.headers),
    Status: `${status} - ${statusText}`,
    Response: typeof data === 'string' ? data : JSON.stringify(data, null, 2),
  };
};

const Propriedades = ({ propriedades, sucesso }) => Object.entries(propriedades).map(([key, value]) => (
  <Propriedade key={key} label={key} content={value} sucesso={sucesso} />
));

function Response({ response }) {
  if (!response) {
    return <div />;
  }

  const { status } = response;
  const propriedades = getPropriedades(response);
  const sucesso = status >= 200 && status < 300;

  return (
    <Container>
      <Propriedades propriedades={propriedades} sucesso={sucesso} />
    </Container>
  );
}

export default Response;
