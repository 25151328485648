import React from 'react';
import TemplateConsulta from 'templates/Consulta';
import FormConsulta from './componentes/FormConsulta';
import ResultadoConsulta from './componentes/ResultadoConsulta';

function Transmissoes() {
  return (
    <TemplateConsulta
      title="Transmissões"
      url="/ftp/transmissoes"
      endPoint="/ftp/transmissoes"
      FormConsulta={FormConsulta}
      ResultadoConsulta={ResultadoConsulta}
    />
  );
}

export default Transmissoes;
