import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Alert } from 'antd';

const createContent = (ContentPage, props) => <ContentPage {...props} />;

const criarRotas = (routes, props) => routes
  .slice()
  .reverse()
  .filter((item) => !!item.content)
  .map((item) => (
    <Route key={item.url} path={`${item.url}/*`} element={createContent(item.content, props)} />
  ));

function ContentMain({ pages, location }) {
  const [error, setError] = useState('');
  if (error) {
    return <Alert message="Erro" type="error" showIcon description={error} />;
  }
  const routes = criarRotas(pages, { setError, location });
  return <Routes>{routes}</Routes>;
}

export default ContentMain;
