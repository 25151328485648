import React from 'react';
import { TFormConsulta } from 'templates/Consulta';
import { Row, Input, DatePeriodo } from 'componentes';

function FormConsulta() {
  const span = 8;
  return (
    <TFormConsulta>
      <Row gutter={4}>
        <DatePeriodo label="Período" name="iniciadaEm~terminadaEm" span={span} />
        <Input label="Nome" name="nome" span={span} />
      </Row>
    </TFormConsulta>
  );
}

export default FormConsulta;
