/* eslint-disable no-unused-vars */
import { combineReducers } from 'redux';
// import { connectRouter } from 'connected-react-router';
import usuarioReducer from './usuario';

const reducers = (history) => combineReducers({
  // router: connectRouter(history),
  usuario: usuarioReducer,
});

export default reducers;
