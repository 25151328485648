import React from 'react';
import TemplateCadastro from 'templates/Cadastro';
import FormConsulta from './componentes/FormConsulta';
import FormCadastro from './componentes/FormCadastro';
import ResultadoConsulta from './componentes/ResultadoConsulta';

function Usuario() {
  return (
    <TemplateCadastro
      title="Configuração FTP"
      url="/ftp/configuracao"
      endPoint="/ftp"
      FormConsulta={FormConsulta}
      ResultadoConsulta={ResultadoConsulta}
      FormCadastro={FormCadastro}
      textoBotaoNovo="Criar nova configuração"
    />
  );
}

export default Usuario;
