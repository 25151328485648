/* eslint-disable */
import React, { useState } from 'react';
import {
  Form, Button,
} from 'componentes';
import {
  Modal, Spin, Row, Col, message, App
} from 'antd';
import { useTemplate } from './Provider';
import { aux } from '../data/useCadastro';

const cancelar = (voltar, modificouAlgumaCoisaNoForm) => () => {
  if (modificouAlgumaCoisaNoForm) {
    return  App.useApp().modal.confirm({
      title: 'Deseja realmente continuar?',
      content: 'Todas as alterações serão perdidas',
      okText: 'Sim',
      cancelText: 'Não',
      onOk() {
        voltar();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  return voltar();
};



function TFormCadastro({
  children,
  title = 'title',
  initialValues = {},
}) {

  const { actions, data } = useTemplate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  const { cadastro } = data;


  const onExcluir = () => {

    Modal.confirm({
    title: 'Deseja realmente excluir?',
    okText: 'Sim',
    cancelText: 'Não',
    okType: 'danger',
    onOk() {
      actions
        .excluir(cadastro.id)
        .then(() => {
          actions.atualizarResultado((data) => data.filter((r) => r.id !== id));
          App.useApp().message.success('Excluído com sucesso', 3);
          actions.voltar();
        })
        .catch((e) => {
          Modal.error({
            title: 'Mensagem de erro',
            content: e.response.data.message,
          });
        });
    },
    onCancel() {
      console.log('Cancel');
    },
  })

};


  const onFinish = (query) => {

    actions
      .salvar({id: cadastro.id,...query})
      .then(() => {
        message.success('Salvo com sucesso', 3);
        setSubmitting(false);
        actions.sincronizarResultado();
        actions.voltar();
      })
      .catch((e) => {
        console.log( e.response.data)
        Modal.error({
          title: 'Mensagem de erro',
          content: e.response.data.message ? e.response.data.message : e.response.data,
        });
      });
  };

  return (
    data && (

      <Modal
        open
        title={title}
        okText="Salvar"
        cancelText="Cancelar"
        onCancel={actions.voltar}

        okButtonProps={{ disabled: cadastro.isLoading, htmlType: 'submit' }}
        maskClosable={false}
        destroyOnClose
        wrapClassName="modal-cadastro"
        footer={(
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              {aux.ehModoEdicao(data) && (
                <Button onClick={onExcluir} disabled={false} type="dashed">
                  Excluir
                </Button>
              )}
            </Col>
            <Col span={12}>
              <Button onClick={cancelar(actions.voltar)} disabled={false} type="dashed">
                Cancelar
              </Button>

              <Button type="primary" onClick={() => form.submit()} disabled={isSubmitting} >
                Salvar
              </Button>
            </Col>
          </Row>
        )}
      >
        <Spin spinning={cadastro.isLoading}>
          <Form initialValues={initialValues} labelAlign="left" form={form} onFinish={onFinish} layout="vertical">
            {children}
          </Form>
        </Spin>
      </Modal>
    )
  );
}

export default TFormCadastro;
