import React from 'react';
import styled from 'styled-components';
import H1 from './H1';

const DivSandBox = styled.div`
  background-color: #fcf6db;
  border: 1px solid #e5e0c6;
  /*background-color: #fff;
  border: 1px solid #e8e8e8;*/
  font-size: 18px;
  font-weight: 300;
  padding: 24px;
`;

function Titulo() {
  return (
    <div>
      <H1>Bem vindo ao RHMED E-social Sandbox</H1>
      <DivSandBox>
        RHMED E-social Sandbox espelha todos os recursos encontrados no ambiente
        de produção. O Sandbox possui uma paridade com os recursos suportados
        pelas APIs no ambiente de produção. Isso significa que você pode testar
        sua integração e ter a certeza que o comportamento será exatamente o mesmo
        no ambiente de produção.
      </DivSandBox>
    </div>
  );
}

function Chamada() {
  return (
    <div>
      {/*

    <H1>Sandbox</H1>
    <DivSandBox2>
      <p>
        Antes de iniciar a execução da sua aplicação em ambiente de Produção, é
        de extrema importância que você consiga testar suas funcionalidades para
        assegurar-se de que irá operar livre de erros. Além de certificar-se de
        que ela cumpra todas as regras definidas pelo uso das APIs.
      </p>
      <p>
        Para auxiliá-lo nos testes, oferecemos o Ambiente de Sandbox (ou
        simplesmente Sandbox), um ambiente modelado especialmente para a
        execução de testes. Em Sandbox uma aplicação pode executar todas as
        operações que executa em ambiente de produção, porém, todas as
        informações são fictícias, não havendo nenhum acesso às informações de
        Produção.
      </p>
      <div style={{ textAlign: 'center' }}>
        <a
          href={process.env.REACT_APP_SANDBOX_URL}
          title="Ir para Sandbox"
          style={{ fontSize: 16 }}
        >
          Acessar Sandbox <Icon type="link" style={{ fontSize: 16 }} />
        </a>
      </div>
    </DivSandBox2>
    */}
    </div>
  );
}

export default {
  Titulo,
  Chamada,
};
