import React from 'react';
import TemplateConsulta from 'templates/Consulta';
import { get } from 'lib/api';
import FormConsulta from './componentes/FormConsulta';
import ResultadoConsulta from './componentes/ResultadoConsulta';

const dataForm = {
  tiposDeEvento: () => get('/tipoevento'),
  empresas: () => get('/empresas'),
  statusDeEvento: () => get('/eventos/status'),
};

function Transmissoes() {
  return (
    <TemplateConsulta
      title="Eventos"
      url="/relatorios/eventos"
      endPoint="/relatorios/eventos"
      FormConsulta={FormConsulta}
      ResultadoConsulta={ResultadoConsulta}
      dataForm={dataForm}
    />
  );
}

export default Transmissoes;
