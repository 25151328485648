/* eslint-disable */

import { objectMap, promiseProps } from 'lib/utils';
import { MODO_INCLUSAO, MODO_EDICAO, MODO_CONSULTA } from './const';

export const ACTION_SET_MODO_CADASTRO = 'SET_MODO_CADASTRO';
export const ACTION_SET_MODO_CONSULTA = 'ACTION_SET_MODO_CONSULTA';
export const ACTION_SET_RESULTADO = 'SET_RESULTADO';
export const ACTION_SET_DADO_CADASTRO = 'ACTION_SET_DADO_CADASTRO';
export const ACTION_SET_DATA_FORM = 'ACTION_SET_DATA_FORM';

const actionModoInclusao = { type: ACTION_SET_MODO_CADASTRO, data: MODO_INCLUSAO };
const actionModoEdicao = { type: ACTION_SET_MODO_CADASTRO, data: MODO_EDICAO };
const actionModoConsulta = { type: ACTION_SET_MODO_CONSULTA, data: MODO_CONSULTA };

const voltar = ({ historico }) => () => (dispatch) => {
  historico.consulta();
  dispatch(actionModoConsulta);
};

const executaAcaoConformeUrl = ({ historico }) => (actions) => () => {
  const { pathname } = historico.getLocation();
  const params = pathname.split('/');
  const ultimaParte = params.pop();
  if (ultimaParte === 'novo') {
    return actions.abrirNovo();
  }
  if (ultimaParte === 'edit') {
    return actions.editar(params.pop());
  }
  return undefined;
};

const consultar = ({ restApi }) => (query) => (dispatch) => {
  dispatch({ type: ACTION_SET_RESULTADO, data: { isLoading: true } });
  return restApi.consultar(query).then((response) => {
    dispatch({
      type: ACTION_SET_RESULTADO,
      data: { isLoading: false, data: response.data, query },
    });
    // historico.push(`?${qs.stringify(query)}`);
    return response.data;
  });
};

const editar = ({ historico, restApi }) => (id) => (dispatch) => {
  console.log('Acao editar');
//  historico.edicao(id);
  dispatch(actionModoEdicao);
  console.log('Acao editar end');
  return restApi.editar(id).then(({ data }) => {
    dispatch({
      type: ACTION_SET_DADO_CADASTRO,
      data: {
        id,
        data,
      },
    });
  });
};

const salvar = ({ restApi }) => ({ id, ...data }) => () => {
  const promiseSalvar = id ? restApi.atualizar(id, data) : restApi.inserir(data);
  return promiseSalvar.then((response) => response.data);
};

const abrirNovo = ({ historico, restApi }) => () => (dispatch) => {
  historico.insercao();
  dispatch(actionModoInclusao);
  return restApi.novo().then(({ data }) => {
    dispatch({
      type: ACTION_SET_DADO_CADASTRO,
      data: {
        data,
      },
    });
  });
};

const excluir = ({ restApi }) => (id) => () => restApi.excluir(id);

const atualizarResultado = () => (fn) => (dispatch, state) => {
  const { resultado } = state;
  const data = fn(resultado.data); // ;.filter(r => r.id !== id);
  dispatch({
    type: ACTION_SET_RESULTADO,
    data: { ...resultado, data },
  });
};

const sincronizarResultado = (actions, state) => () => {
  const { resultado } = state;
  const { query } = resultado;
  return actions.consultar(query);
};

const loadDataForm = () => (dataForm) => (dispatch) => {
  dispatch({ type: ACTION_SET_DATA_FORM, data: { isLoading: true } });
  promiseProps(dataForm, ({ data }) => data).then((data) => {
    dispatch({ type: ACTION_SET_DATA_FORM, data: { isLoading: false, data } });
    return data;
  });
};

const createThunk = (state, dispatch) => (action) => (...args) => {
  const result = action(...args);
  if (typeof result === 'function') {
    return result(dispatch, state);
  }
  return dispatch(result);
};

const createThunkActions = (state, dispatch, deps) => (actions) => {
  const thunk = createThunk(state, dispatch);
  return objectMap(actions, (action) => thunk(action(deps)));
};

const createActions = (tools) => (state, dispatch) => {
  const actions = createThunkActions(state, dispatch, tools)({
    abrirNovo,
    editar,
    voltar,
    consultar,
    salvar,
    excluir,
    atualizarResultado,
    loadDataForm,
  });

  return {
    ...actions,
    executaAcaoConformeUrl: executaAcaoConformeUrl(tools)(actions),
    sincronizarResultado: sincronizarResultado(actions, state),
  };
};

export default createActions;
