import getUser from 'lib/get-user';

const getDefaultValue = (parameter) => {
  switch (parameter.name) {
    case 'Authorization':
      return `Bearer ${getUser().token}`;
    default:
      return parameter.default;
  }
};

const mapParameterValue = (parameter) => ({
  [parameter.name]: getDefaultValue(parameter),
});

const reduceMerge = (a, b) => ({ ...a, ...b });

const buildInitialValues = (parameters) => Object.entries(parameters)
  .map(([key, value]) => ({
    [key]: value.map(mapParameterValue).reduce(reduceMerge, {}),
  }))
  .reduce(reduceMerge, {});

export default buildInitialValues;
