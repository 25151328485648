import React from 'react';
import TemplateContent from 'pages/Main/components/TemplateContent';
import Content from './Content';

const urlBase = '/ftp';

const menuData = (urlSelecionada) => ({
  defaultSelectedKeys: [urlSelecionada || `${urlBase}/introducao`],
  // defaultOpenKeys: ['Começando'],
  itens: [
    { label: 'Introdução', url: `${urlBase}/introducao` },
    { label: 'Configuração', url: `${urlBase}/configuracao` },
    { label: 'Transmissões', url: `${urlBase}/transmissoes` },
  ],
});

function Ftp({ location }) {
  return (
    <TemplateContent menuData={menuData(location.pathname)}>
      <Content location={location} urlBase={urlBase} />
    </TemplateContent>
  );
}

export default Ftp;
