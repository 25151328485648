import React from 'react';
import { Provider } from 'react-redux';
import ErrorPage from 'error-page';

import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import localPtBr from 'antd/es/locale/pt_BR';
import configureStore from './configureStore';
import App from './App';

const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
    errorElement: <ErrorPage />,
  },
]);

const theme = {
  token: {
    colorPrimary: '#891635',
    fontSize: 12,
    lineHeight: '1.0',
    itemMarginBottom: 14,
  },
  components: {
    DatePicker: {
      cellRangeBorderColor: 'green',
    },
    Button: {
      primaryShadow: '0 0px 0 rgba(0, 0, 0, 0.02)',
    },
    Input: {
      paddingInline: '5',
      paddingBlock: '6',
    },
    Modal: {
      headerBg: '#40948a',
      titleColor: 'rgba(255, 255, 255, 0.65)',
    },
  },
};

ConfigProvider.config({
  theme: {
    colorPrimaryBg: 'yellow',
    token: {
      colorPrimaryBg: 'yellow',
      fontSize: 11,
    },
    components: {
      DatePicker: {
        activeBg: '#000',
        activeBorderColor: 'red',
      },
    },
  },
});

const store = configureStore();

function Root() {
  return (
    <Provider store={store}>

      <ConfigProvider locale={localPtBr} theme={theme}>
        <RouterProvider router={router} />
      </ConfigProvider>

    </Provider>
  );
}
export default Root;
