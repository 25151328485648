import { requestAnonimo } from 'lib/api';

export const executarAPI = ({ setResponse, route, data }) => {
  const { url, method } = route;
  const endPoint = data.params ? url.replace(/\{.+?\}/, data.params.id) : url;
  const r = requestAnonimo;

  return r({
    method,
    url: endPoint,
    params: data.query,
    data: data.payload,
    headers: data.header,
  })
    .then(setResponse)
    .catch((error) => {
      setResponse(error.response);
    });
};

/* const executarAPI = ({ route, data }) => {
  const { url, method, operationId } = route;
  return (dispatch) => {
    dispatch({
      type: LOADING_API,
      operationId,
      loading: true,
    });
    const endPoint = data.params ? url.replace(/\{.+?\}/, data.params.id) : url;
    const r = requestAnonimo;
    r({
      method,
      url: endPoint,
      params: data.query,
      data: data.payload,
      headers: data.header,
    })
      .then((response) => {
        dispatch({
          type: LOAD_RESPONSE,
          operationId,
          response,
        });
      })
      .catch((error) => {
        dispatch({
          type: LOAD_RESPONSE,
          operationId,
          response: error.response,
        });
      });
  };
}; */
