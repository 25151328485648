import React, { useContext } from 'react';
import useCadastro from '../data/useCadastro';

const TemplateContext = React.createContext();

export function TemplateProvider({
  children, restApi, historico, dataForm,
}) {
  const contextValue = useCadastro({ restApi, historico, dataForm });
  return <TemplateContext.Provider value={contextValue}>{children}</TemplateContext.Provider>;
}

export const useTemplate = () => {
  const contextValue = useContext(TemplateContext);
  return contextValue;
};
