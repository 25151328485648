const addAcceptParameter = (parameters, produces) => {
  if (produces) {
    return parameters.concat({
      description: 'Tipo de conteúdo que deseja receber',
      in: 'header',
      name: 'Accept',
      required: true,
      type: 'string',
      enum: produces,
      default: produces[0],
    });
  }
  return parameters;
};

export default addAcceptParameter;
