import { useReducer } from 'react';
import createActions from './actions';
import reducer from './reducer';

const getInitialState = (dataForm) => ({
  dataForm: { isLoading: !!dataForm },
  resultado: { isLoading: false },
});

const useCadastro = ({ restApi, historico, dataForm }) => {
  const [data, dispatch] = useReducer(reducer, getInitialState(dataForm));
  const actions = createActions({ restApi, dispatch, historico });
  return {
    actions,
    data,
  };
};

export default useCadastro;
