import React from 'react';
import styled from 'styled-components';
import logoRhmed from '../../../assets/images/rhmed_rhvida_logo.png';

const Quadro = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 20px 20px 15px;
`;

function LogoRHMED() {
  return (
    <Quadro>
      <img src={logoRhmed} alt="logo" />
    </Quadro>
  );
}

export default LogoRHMED;
