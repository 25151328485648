import React, { useState } from 'react';
import { TResultadoConsulta } from 'templates/Consulta';
import { Button } from 'antd';
import {
  DownloadOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import moment from 'moment'; // TODO: remove moment
import * as perfil from 'lib/perfil';
import { download, downloadZipResultado } from '../../data/actions';
import EventoErrosDetalhe from './EventoErrosDetalhe';

const columns = {
  datIniCondicao: {
    title: 'Inicio Condição',
    dataIndex: 's2240_datIniCondicao',
    sorter: 'datetime',
    render: (text) => moment(text).add(3, 'h').format('DD/MM/YYYY'),
  },

  datEvento: {
    title: 'Data do Evento',
    dataIndex: 'datEvento',
    sorter: 'datetime',
    render: (text) => moment(text).format('DD/MM/YYYY'),
  },
  tipoEvento: {
    title: 'Tipo do Evento',
    dataIndex: 'tipoEvento',
  },
  cnpj: {
    title: 'CNPJ',
    dataIndex: 'cnpj',
  },
  cpf: {
    title: 'CPF',
    dataIndex: 'cpf',
  },
  empresa: {
    title: 'Empresa',
    dataIndex: 'empresa',
    sorter: true,
  },
  status: {
    title: 'Status',
    dataIndex: 'status',
    sorter: true,
  },

  action: {
    title: 'Ação',
    key: 'action',
    render: (text, record) => (
      <span key={record.id}>
        {record.status === 'Aguardando Envio' && (
          <Button onClick={() => download(record.id)} title="Download do arquivo xml">
            <DownloadOutlined />
          </Button>
        )}
      </span>
    ),
  },
};

const createColumns = (usuario, setIdEventoInvalido, columnsName) => {
  const action = {
    title: 'Ação',
    key: 'action',
    render: (text, record) => (
      <span key={record.id}>
        {record.status === 'Aguardando Envio' && (
          <Button onClick={() => download(record.id)} title="Download do arquivo xml">
            <DownloadOutlined />
          </Button>
        )}
        {record.status === 'Ocorrência Invalida' && (
          <Button onClick={() => setIdEventoInvalido(record.id)} title="Visualizar erros">
            <FileSearchOutlined />
          </Button>
        )}
      </span>
    ),
  };
  const {
    cnpj, cpf, datEvento, tipoEvento, empresa, status, datIniCondicao,
  } = columns;

  if (perfil.ehClient(usuario)) {
    if (columnsName.indexOf('s2240_datIniCondicao') > -1) {
      return [[datEvento, '10%'], [datIniCondicao, '10%'], [tipoEvento, '20%'], [cnpj, '20%'], [cpf, '30%'], [action]];
    }

    return [[datEvento, '20%'], [tipoEvento, '20%'], [cnpj, '20%'], [cpf, '30%'], [action]];
  }

  if (columnsName.indexOf('s2240_datIniCondicao') > -1) {
    return [
      [empresa, '20%'],
      [datEvento, '10%'],
      [datIniCondicao, '10%'],
      [tipoEvento, '10%'],
      [status, '20%'],
      [cnpj, '10%'],
      [cpf, '10%'],
      [action],
    ];
  }

  return [
    [empresa, '20%'],
    [datEvento, '20%'],
    [tipoEvento, '10%'],
    [status, '20%'],
    [cnpj, '10%'],
    [cpf, '10%'],
    [action],
  ];
};

function ResultadoConsulta({
  data, width, height, usuario, isLoading,
}) {
  const [idEventoInvalido, setIdEventoInvalido] = useState(null);
  const dataReduzido = data;// ? data.slice(0, 100) : undefined;

  const columnsName = data && dataReduzido[0] ? Object.keys(dataReduzido[0]) : [];

  return (
    <>
      <TResultadoConsulta
        data={dataReduzido}
        columns={createColumns(usuario, setIdEventoInvalido, columnsName)}
        width={width}
        height={height}
        isLoading={isLoading}
        rowClassName={(record) => (record.status === 'Ocorrência Invalida' ? 'ant-alert-error' : '')}
        downloadZipResultado={downloadZipResultado}
      />
      {idEventoInvalido && (
        <EventoErrosDetalhe idEvento={idEventoInvalido} onOk={() => setIdEventoInvalido(null)} />
      )}
    </>
  );
}

export default ResultadoConsulta;
