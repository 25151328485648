import axios from 'axios';
import store from 'store';
import qs from 'qs';
import getUrlBase from './getUrlBase';

const baseURL = `${getUrlBase()}/`;

const setAuthorizationHeader = (headers) => {
  if (store.get('usuario')) {
    return {
      Authorization: `Bearer ${store.get('usuario').token}`,
      ...headers,
      Accept: 'application/json',
    };
  }
  return headers;
};

const defaultConfig = {
  baseURL,
  // timeout: 1000,
  paramsSerializer: (query) => qs.stringify(query, { arrayFormat: 'repeat' }),
};

const cliente = axios.create(setAuthorizationHeader(defaultConfig));

const anonimo = axios.create({
  baseURL,
  // timeout: 1000,
  paramsSerializer: (query) => qs.stringify(query, { arrayFormat: 'repeat' }),
});

export const request = (options) => {
  const { headers = {} } = options;
  return cliente.request({
    ...options,
    headers: setAuthorizationHeader(headers),
    // withCredentials: true,
  });
};

export const post = (url, data) => request({ method: 'POST', url, data });
export const get = (url, params) => request({ method: 'GET', url, params });
export const download = (url, params) => request({
  method: 'GET', url, params, responseType: 'blob',
});

export const put = (url, data) => request({ method: 'PUT', url, data });
export const deleteMethod = (url) => request({ method: 'DELETE', url });
export const requestAnonimo = (options) => anonimo.request(options);

export const createRestApi = (endPoint) => ({
  consultar(query) {
    return get(endPoint, query);
  },
  editar(id) {
    return get(`${endPoint}/${id}/editar`);
  },
  novo() {
    return get(`${endPoint}/novo`);
  },
  atualizar(id, data) {
    return put(`${endPoint}/${id}`, data);
  },
  inserir(data) {
    return post(`${endPoint}`, data);
  },
  excluir(id) {
    return deleteMethod(`${endPoint}/${id}`);
  },
});

const api = {
  get,
  put,
  post,
  request,
};
export default api;
