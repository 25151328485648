import React from 'react';
import {
  Table, Button, Row, Col,
} from 'antd';
import styled from 'styled-components';

const Footer = styled.div`
  position: absolute !important;
  bottom: 0px;
  border-top: 2px solid #e8e8e8 !important;
  width: 100%;
  line-height:28px;
`;

const footer = (data) => (data ? `Número de Linhas: ${data.length}` : 'RHMED❤️RHVIDA');

const compareDateString = (dataIndex) => (a, b) => {
  const date1 = new Date(a[dataIndex]);
  const date2 = new Date(b[dataIndex]);
  return date1.getTime() - date2.getTime();
};

const compareNumber = (dataIndex) => (a, b) => a[dataIndex] - b[dataIndex];

const getSorter = ({ sorter, dataIndex }) => {
  const type = typeof sorter;
  if (type === 'string') {
    switch (sorter) {
      case 'datetime':
        return compareDateString(dataIndex);
      case 'number':
        return compareNumber(dataIndex);
      default: {
        return sorter;
      }
    }
  }
  return sorter;
};

const mapColumn = (columns) => columns.map((column) => {
  if (Array.isArray(column)) {
    const [c, width] = column;
    return {
      ...c,
      width,
    };
  }
  return column;
});

const mapSorterColumn = (columns) => columns.map((column) => {
  if (column.sorter) {
    return {
      ...column,
      sorter: getSorter(column),
    };
  }
  return column;
});

function ResultadoConsulta({
  data,
  columns,
  rowKey = 'id',
  width,
  height,
  isLoading,
  downloadZipResultado,
  ...restProps
}) {
  const scroll = { y: height - 94 };
  const locale = data ? { emptyText: 'Sem Registros' } : { emptyText: 'Realize a consulta' };

  return (
    <>
      <Table
        rowKey={rowKey}
        dataSource={data}
        columns={mapSorterColumn(mapColumn(columns))}
        pagination={false}
        scroll={scroll && { y: scroll.y }}
        locale={locale}
        loading={isLoading}
        // footer={footer}
        {...restProps}
      />
      <Footer className="ant-table-footer">
        {downloadZipResultado && data && data.length > 0
          ? (
            <Row>
              <Col span={12} style={{ textAlign: 'left' }}>{footer(data)}</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button type="default" onClick={() => downloadZipResultado(data.query)}>
                  Download Zip
                </Button>
              </Col>
            </Row>
          ) : footer(data)}
      </Footer>
    </>
  );
}

export default ResultadoConsulta;
