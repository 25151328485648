/* eslint-disable */
import React from 'react';
import { TFormCadastro } from 'templates/Cadastro';
import { Input, Select, Row } from 'componentes';

const toOptions = (data, label, value) => {
  if (Array.isArray(data)) {
    return data.map((d) => ({
      label: d[label],
      value: d[value],
    }));
  }
  return [];
};

function FormCadastro({ data,isLoading }) {
  const empresas = toOptions(data.empresas, 'nome', 'id');
  const perfis = toOptions(data.perfis, 'nome', 'id');

  if(isLoading){
    return "ok";
  }

  // return "ok";
  return (
    <TFormCadastro initialValues={data.usuario} title="Usuário">
      <Row gutter={2}>
        <Input label="Nome" name="nome" span={12} required autoFocus />
        <Input label="E-mail" name="email" span={12} required />
      </Row>
      <Row gutter={2}>
        <Input label="Login" name="login" span={12} required />
        <Input label="Senha" name="senha" span={12} type="password" required min="8" />
      </Row>
      <Row gutter={2}>
        <Select label="Empresa" name="idEmpresa" options={empresas} span={12} required />
        <Select label="Perfil" name="idUsuarioPerfil" options={perfis} span={12} required />
      </Row>
    </TFormCadastro>
  );
}

export default FormCadastro;
