import React from 'react';
// import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Main from './pages/Main';
import Login from './pages/Login';

const mapStateToProps = (state) => ({
  usuario: state.usuario,
});

function App({ usuario }) {
  if (usuario.estaLogado) {
    return <Main />;
  }
  return <Login />;
}

export default connect(mapStateToProps)(App);
