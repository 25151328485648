import React from 'react';
import Recurso from './components/Recurso';

// eslint-disable-next-line max-len
const buscarPorTag = (tagSelecionada, routes) => routes.filter(({ tags }) => tags.includes(tagSelecionada));

function DocumentacaoInterativa(props) {
  const { location, swaggerData } = props;
  const tagSelecionada = location.pathname.split('/').slice(-1)[0];

  const recursos = buscarPorTag(tagSelecionada, swaggerData);
  return (
    <div style={{ margin: '10px 5px' }}>
      {recursos.map((route) => (
        <Recurso key={route.operationId} route={route} />
      ))}
    </div>
  );
}

export default DocumentacaoInterativa;
