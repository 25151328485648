/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

function MenuPrincipal({ urlSelecionada, pages }) {
  const itens = pages.map((item) => (
    {
      label: <Link to={item.url}>{item.text}</Link>,
      key: item.url
    }
  ))

  return (
    <Menu
      color='red'
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={[urlSelecionada]}
      style={{ lineHeight: '84px', minWidth: '400px' }}
      items={itens}
    />
  );
}

export default MenuPrincipal;
