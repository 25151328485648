/* eslint-disable */

import React from 'react';
import TemplateCadastro from 'templates/Cadastro';
import { get } from 'lib/api';
import FormConsulta from './componentes/FormConsulta';
import FormCadastro from './componentes/FormCadastro';
import ResultadoConsulta from './componentes/ResultadoConsulta';

const dataForm = {
  empresas: () => get('/empresas'),
};

function Usuario() {
  console.log("Index usuário");
  return (
    <TemplateCadastro
      dataForm={dataForm}
      title="Usuários"
      url="/admin/usuarios"
      endPoint="/usuarios"
      FormConsulta={FormConsulta}
      ResultadoConsulta={ResultadoConsulta}
      FormCadastro={FormCadastro}
      textoBotaoNovo="Novo usuário"
    />
  );
}

export default Usuario;
