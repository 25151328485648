import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import store from 'store';
import {
  Dropdown, Menu, Avatar,
} from 'antd';

import {
  LogoutOutlined,
  DownOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { get } from 'lib/api';

const logout = () => () => {
  store.remove('usuario');
  window.location.href = '/';
  /*
  dispatch({
    type: 'USER_LOGOUT',
  });
  */
};

const getMenus = (sair) => [{
  key: 1,
  onClick: sair,
  onKeyPress: sair,
  icon: <LogoutOutlined />,
  label: 'Sair',
}];

const createMenu = ({ sair }) => (
  <Menu items={getMenus(sair)} />
);

function UsuarioButton() {
  const [usuario, setUsuario] = useState(null);
  const dispatch = useDispatch();
  const sair = logout(dispatch);
  useEffect(() => {
    get('/me').then(({ data }) => setUsuario(data));
  }, []);
  if (!usuario) {
    return null;
  }
  return (
    <div>
      <Avatar size="default" shape="square" icon={<UserOutlined />} />
      <Dropdown overlay={createMenu({ sair })}>
        <span style={{ color: '#fff', paddingLeft: '10px' }}>
          {usuario.nome}
          <DownOutlined type="down" />
        </span>
      </Dropdown>
    </div>
  );
}
export default UsuarioButton;
